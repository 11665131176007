$(function () {
  window.getCookie = cookie => {
    const cookies = cookie.split(';').map(item => item.split('='));
    const cookiesObject = cookies.reduce((result, value) => {
      result[decodeURIComponent(value[0]?.trim())] = decodeURIComponent(value[1]?.trim());
      return result;
    }, {});
    return cookiesObject;
  };

  window.setCookie = (cname, cvalue, exdays, path) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    document.cookie = `${cname}=${cvalue};expires=${d.toUTCString()};path=${path}`;
  };
});