$(function () {
  $('.slider__body').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 600,
    prevArrow: '<div class="slider__arrow slider__arrow--prev"><img src="./build/img/icons/arrow.svg" alt></div>',
    nextArrow: '<div class="slider__arrow slider__arrow--next"><img src="./build/img/icons/arrow.svg" alt></div>',
  });
});