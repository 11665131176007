$(function () {
	$('.header__burger').on('click', function () {
		$(this).closest('.header').toggleClass('show-menu');
	});

	$('.header__menu a').on('click', function (e) {
		e.preventDefault();
		$('.header').removeClass('show-menu');

		const id = `#${$(this).attr('href').split('#')[1]}`;
		$('html').animate({
			scrollTop: `${$(id).offset().top - $('.header').outerHeight()}px`
		}, 300);
	});
});