$(function () {
  $('body').on('click', '.popup-open', function (e) {
    e.preventDefault();
    popupOpen($(this).attr('id'));
  });

  $('.popup__overlay, .popup__close').on('click', function (e) {
    e.preventDefault();
    $('body').removeClass('no-scroll');
    $('.popup').removeClass('is-active');
  });

  window.popupOpen = (id) => {
    $('body').addClass('no-scroll');
    $('.popup').removeClass('is-active');
    $(id).addClass('is-active');
  };

  $(window).on('load resize', function () {
    if ($(this).width() < 768) {
      $('body').removeClass('no-scroll');
      $('.popup').removeClass('is-active');
    }
  });
});